const Leafdesktop = (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 26 31"
    xmlSpace="preserve"
  >
    <style type="text/css">{'\n.st0{fill:#330066;}\n'}</style>
    <g>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1.8s"
        from={0}
        to={0}
        values="0 18 30;12 18 30;0 18 30"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
        calcMode="spline"
        repeatCount="indefinite"
      />
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M9.5,10.5C7.2,7.1,9.2,2,13.1,1.2C13.2,4.7,11.5,7.9,9.5,10.5z"
            />
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1.8s"
              from={0}
              to={0}
              values="0 10 13;6 10 13;0 10 13"
              keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
              calcMode="spline"
              begin={0.4}
              repeatCount="indefinite"
            />
          </g>
          <path
            className="st0"
            d="M7.3,7.4c1.3,3.2,1.9,6.7,1.8,10.1C5,15.6,4.4,9.8,6.9,6.4C7,6.4,7.2,7.1,7.3,7.4z"
          />
          <path
            className="st0"
            d="M12.4,13.9c-0.5,1-1.1,2.2-1.7,3.1c-2.2-2.8-1.1-7.5,2.5-8.3C13.4,10.4,13.2,12.3,12.4,13.9z"
          />
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1.8s"
            from={0}
            to={0}
            values="0 12 16;4 12 16;0 12 16"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
            calcMode="spline"
            begin={0.2}
            repeatCount="indefinite"
          />
        </g>
        <path
          className="st0"
          d="M7.6,20.9c-0.6-1.2-1-2.7-0.9-4c2.8,1.7,5,4.4,6.2,7.4C10.8,24.3,8.6,22.9,7.6,20.9z"
        />
        <path
          className="st0"
          d="M14.2,23.2c0,0.1,0,0.1,0,0.1c-2.7-1.4-3.8-5.4-1.6-7.8C14.2,17.7,14.6,20.6,14.2,23.2z"
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1.8s"
          from={0}
          to={0}
          values="0 12 24;4 12 24;0 12 24"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
          begin={0.1}
          repeatCount="indefinite"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M11.2,24.9c2.6,0.2,5.1,1.5,6.8,3.4C15.3,29.3,12.2,27.4,11.2,24.9z"
        />
        <path
          className="st0"
          d="M17.9,24.7c0.3,0.7,0.6,1.6,0.8,2.4c-2.5-0.3-4.4-2.9-3.6-5.3C16.1,22.3,17.3,23.3,17.9,24.7z"
        />
      </g>
    </g>
  </svg>
);
export default Leafdesktop;
