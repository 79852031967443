const LocalGuideIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M11.1607 17.9997V16.839C11.1607 16.2233 10.9161 15.6329 10.4808 15.1975C10.0454 14.7622 9.45497 14.5176 8.83929 14.5176H4.19643C3.58075 14.5176 2.99028 14.7622 2.55493 15.1975C2.11958 15.6329 1.875 16.2233 1.875 16.839V17.9997"
      fill="#B64E80"
    />
    <path
      d="M11.1607 17.9997V16.839C11.1607 16.2233 10.9161 15.6329 10.4808 15.1975C10.0454 14.7622 9.45497 14.5176 8.83929 14.5176H4.19643C3.58075 14.5176 2.99028 14.7622 2.55493 15.1975C2.11958 15.6329 1.875 16.2233 1.875 16.839V17.9997H11.1607Z"
      stroke="#B64E80"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25014 12.6429C7.53223 12.6429 8.57157 11.6035 8.57157 10.3214C8.57157 9.03934 7.53223 8 6.25014 8C4.96805 8 3.92871 9.03934 3.92871 10.3214C3.92871 11.6035 4.96805 12.6429 6.25014 12.6429Z"
      fill="#B64E80"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9281 4.71113L11.4107 1.99554C11.3343 1.96368 11.25 2.01985 11.25 2.10268V7.15179L17.9202 4.92839C18.022 4.89445 18.0272 4.7524 17.9281 4.71113Z"
      fill="#B64E80"
    />
    <path
      d="M11.25 12.375L11.25 7.15179M11.25 7.15179V2.10268C11.25 2.01985 11.3343 1.96368 11.4107 1.99554L17.9281 4.71113C18.0272 4.7524 18.022 4.89445 17.9202 4.92839L11.25 7.15179Z"
      stroke="#B64E80"
      strokeWidth="1.16071"
      strokeLinecap="round"
    />
  </svg>
);
export default LocalGuideIcon;
