import COLORS from 'const/colors';
import { CARD_SECTION_MARKERS } from 'const/productCard';

const StarFull = ({ fillColor = COLORS.PRIMARY.JOY_MUSTARD }) => (
  <svg id="star-full" viewBox="0 0 475.075 475.075">
    <path
      data-card-section={CARD_SECTION_MARKERS.REVIEWS}
      fill={fillColor}
      d="M475.075,186.573c0-7.043-5.328-11.42-15.992-13.135L315.766,152.6L251.529,22.694c-3.614-7.804-8.281-11.704-13.99-11.704
        c-5.708,0-10.372,3.9-13.989,11.704L159.31,152.6L15.986,173.438C5.33,175.153,0,179.53,0,186.573c0,3.999,2.38,8.567,7.139,13.706
        l103.924,101.068L86.51,444.096c-0.381,2.666-0.57,4.575-0.57,5.712c0,3.997,0.998,7.374,2.996,10.136
        c1.997,2.766,4.993,4.142,8.992,4.142c3.428,0,7.233-1.137,11.42-3.423l128.188-67.386l128.197,67.386
        c4.004,2.286,7.81,3.423,11.416,3.423c3.819,0,6.715-1.376,8.713-4.142c1.992-2.758,2.991-6.139,2.991-10.136
        c0-2.471-0.096-4.374-0.287-5.712l-24.555-142.749l103.637-101.068C472.604,195.33,475.075,190.76,475.075,186.573z"
    />
  </svg>
);
export default StarFull;
