import { SVGProps } from 'react';

export const Spark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={41}
    viewBox="0 0 36 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.6422 5.34925L4.30244 19.9315C2.69691 22.4382 4.63427 25.7037 7.60382 25.496L12.6124 25.1458L12.2486 33.3766C12.0827 37.1289 17.0354 38.6206 18.9693 35.4007L28.8807 18.8995C30.3889 16.3885 28.4463 13.2192 25.5244 13.4235L20.1541 13.799L20.3106 7.39038C20.4 3.7286 15.6178 2.26481 13.6422 5.34925Z"
      fill="#FDB0D5"
      stroke="#FDB0D5"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5812 5.95063L5.24136 20.5329C4.13028 22.2676 5.471 24.5274 7.52604 24.3837L13.7815 23.9463L13.3625 33.4258C13.2477 36.0226 16.6751 37.0549 18.0135 34.8266L27.9248 18.3254C28.9686 16.5877 27.6243 14.3944 25.6022 14.5358L19.0095 14.9968L19.1959 7.36316C19.2578 4.82907 15.9483 3.81607 14.5812 5.95063Z"
      fill="#FDB0D5"
      stroke="#CE007C"
      strokeWidth={3.77}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_3814_911"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={6}
      y={6}
      width={21}
      height={29}
    >
      <path
        d="M17.3115 7.31714C17.3268 6.68951 16.5071 6.43862 16.1685 6.96729L6.82869 21.5496C6.5535 21.9792 6.88557 22.5389 7.39455 22.5033L15.064 21.967C15.4338 21.9412 15.7437 22.2432 15.7273 22.6135L15.2457 33.5091C15.2172 34.1522 16.0661 34.4079 16.3976 33.856L26.3089 17.3548C26.5674 16.9244 26.2345 16.3812 25.7337 16.4162L17.7548 16.9741C17.3899 16.9997 17.0822 16.7056 17.0911 16.3399L17.3115 7.31714Z"
        fill="#FDB0D5"
      />
    </mask>
    <g mask="url(#mask0_3814_911)">
      <ellipse
        cx={11.4295}
        cy={14.2844}
        rx={10.2083}
        ry={14.5833}
        transform="rotate(-4 11.4295 14.2844)"
        fill="#FDD3E7"
      />
    </g>
    <path
      d="M21.3304 13.5074C21.3988 13.2333 21.7779 13.2068 21.8838 13.4687L23.2268 16.7908C23.2617 16.8773 23.336 16.9419 23.4265 16.9645L26.9032 17.8321C27.1773 17.9004 27.2038 18.2796 26.9419 18.3854L23.6198 19.7285C23.5333 19.7634 23.4687 19.8377 23.4462 19.9282L22.5786 23.4049C22.5102 23.679 22.131 23.7055 22.0252 23.4436L20.6822 20.1215C20.6472 20.035 20.5729 19.9704 20.4824 19.9478L17.0057 19.0803C16.7316 19.0119 16.7051 18.6327 16.967 18.5269L20.2891 17.1839C20.3756 17.1489 20.4402 17.0746 20.4628 16.9841L21.3304 13.5074Z"
      fill="white"
    />
    <path
      d="M24.174 10.4312L23.6743 5.13135L20.174 5.33115C20.3302 5.32627 20.674 12.9312 20.674 12.9312C31.174 10.4312 30.6743 16.6038 30.1743 18.4371L22.1743 32.4361L24.6743 32.1361L31.6743 20.9371C36.674 11.4312 27.6736 9.42519 24.174 10.4312Z"
      fill="#CE007C"
      stroke="#CE007C"
      strokeLinecap="round"
    />
  </svg>
);

export const Diamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={38}
    height={37}
    viewBox="0 0 38 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4101_7162)">
      <path
        d="M17.3304 31.841C17.3853 31.8757 17.4355 31.9074 17.481 31.9358C17.8668 32.2824 18.414 32.6843 19.0929 32.9308C19.5728 33.1049 20.3242 33.2748 21.1932 33.0738C22.0135 32.884 22.6713 32.4256 23.137 31.8507C23.1987 31.7845 23.2819 31.6945 23.3843 31.5819C23.6078 31.3363 23.923 30.983 24.3047 30.5368C25.0666 29.6461 26.1013 28.3762 27.2026 26.847C29.3646 23.8449 31.9555 19.6097 33.0838 15.1606C33.0872 15.1473 33.0909 15.1329 33.0948 15.1174C33.1618 14.8554 33.306 14.2909 33.3344 13.6804C33.3674 12.97 33.2609 11.9669 32.5966 11.0063L32.5434 10.9294L32.4856 10.856L28.1716 5.38075C27.4362 4.37999 26.1499 3.56451 24.57 3.71076L24.5478 3.71282L24.5256 3.7152L9.6412 5.31663C7.97091 5.48419 6.82883 6.64247 6.33712 7.85259C6.33724 7.85229 6.337 7.8529 6.33712 7.85259C6.33688 7.85318 6.33627 7.85469 6.33603 7.85528L3.6867 14.3705L3.68146 14.3835C2.90004 16.3312 3.84915 18.0653 4.12273 18.5652C4.12575 18.5707 4.12868 18.5761 4.13153 18.5813L4.13384 18.5855C6.40853 22.7296 9.87018 26.1123 12.6334 28.402C14.0346 29.5631 15.3017 30.4802 16.2237 31.1105C16.6855 31.4261 17.0631 31.6715 17.3304 31.841Z"
        fill="#B473FF"
      />
      <path
        d="M6.33712 7.85259C6.82883 6.64247 7.97091 5.48419 9.6412 5.31663L24.5256 3.7152L24.5478 3.71282L24.57 3.71076C26.1499 3.56451 27.4362 4.37999 28.1716 5.38075L32.4856 10.856L32.5434 10.9294L32.5966 11.0063C33.2609 11.9669 33.3674 12.97 33.3344 13.6804C33.306 14.2909 33.1618 14.8554 33.0948 15.1174C33.0909 15.1329 33.0872 15.1473 33.0838 15.1606C31.9555 19.6097 29.3646 23.8449 27.2026 26.847C26.1013 28.3762 25.0666 29.6461 24.3047 30.5368C23.923 30.983 23.6078 31.3363 23.3843 31.5819C23.2819 31.6945 23.1987 31.7845 23.137 31.8507C22.6713 32.4256 22.0135 32.884 21.1932 33.0738C20.3242 33.2748 19.5728 33.1049 19.0929 32.9308C18.414 32.6843 17.8668 32.2824 17.481 31.9358C17.4355 31.9074 17.3853 31.8757 17.3304 31.841C17.0631 31.6715 16.6855 31.4261 16.2237 31.1105C15.3017 30.4802 14.0346 29.5631 12.6334 28.402C9.87018 26.1123 6.40853 22.7296 4.13384 18.5855L4.13153 18.5813C4.12868 18.5761 4.12575 18.5707 4.12273 18.5652C3.84915 18.0653 2.90004 16.3312 3.68146 14.3835L3.6867 14.3705L6.33603 7.85528C6.33627 7.85469 6.33688 7.85318 6.33712 7.85259ZM6.33712 7.85259C6.337 7.8529 6.33724 7.85229 6.33712 7.85259Z"
        stroke="#B283E7"
        strokeWidth={6}
      />
      <path
        d="M17.9273 30.8995C18.0325 30.9662 18.1195 31.0204 18.1866 31.0618C18.7464 31.5355 19.4747 31.8065 20.2564 31.7427C21.072 31.6762 21.7748 31.2586 22.2516 30.6482C22.3111 30.5885 22.3921 30.5066 22.4921 30.4037C22.7109 30.1784 23.0213 29.852 23.398 29.4374C24.1503 28.6092 25.1724 27.423 26.2597 25.9824C28.4089 23.1345 30.9222 19.1495 32.0033 14.8866C32.0061 14.8754 32.009 14.8638 32.0121 14.8518C32.0794 14.5875 32.198 14.1214 32.2208 13.6287C32.2466 13.0734 32.1619 12.3375 31.6797 11.6404L31.6463 11.592L31.61 11.5459L27.2839 6.05533C26.7373 5.30123 25.7986 4.71656 24.6728 4.82077L24.6588 4.82207L24.6449 4.82357L9.75595 6.42547C8.57179 6.54301 7.73768 7.36656 7.36969 8.27252C7.36976 8.27233 7.36962 8.2727 7.36969 8.27252C7.36953 8.2729 7.36916 8.27383 7.369 8.27421L4.71927 14.7904L4.71597 14.7986C4.13777 16.2398 4.84233 17.5578 5.10953 18.0465L5.11098 18.0491C7.28872 22.0166 10.6297 25.2939 13.3446 27.5437C14.7144 28.6788 15.9532 29.5753 16.8527 30.1902C17.303 30.498 17.6698 30.7362 17.9273 30.8995Z"
        fill="#B473FF"
      />
      <path
        d="M7.36969 8.27252C7.73768 7.36656 8.57179 6.54301 9.75595 6.42547L24.6449 4.82357L24.6588 4.82207L24.6728 4.82077C25.7986 4.71656 26.7373 5.30123 27.2839 6.05533L31.61 11.5459L31.6463 11.592L31.6797 11.6404C32.1619 12.3375 32.2466 13.0734 32.2208 13.6287C32.198 14.1214 32.0794 14.5875 32.0121 14.8518C32.009 14.8638 32.0061 14.8754 32.0033 14.8866C30.9222 19.1495 28.4089 23.1345 26.2597 25.9824C25.1724 27.423 24.1503 28.6092 23.398 29.4374C23.0213 29.852 22.7109 30.1784 22.4921 30.4037C22.3921 30.5066 22.3111 30.5885 22.2516 30.6482C21.7748 31.2586 21.072 31.6762 20.2564 31.7427C19.4747 31.8065 18.7464 31.5355 18.1866 31.0618C18.1195 31.0204 18.0325 30.9662 17.9273 30.8995C17.6698 30.7362 17.303 30.498 16.8527 30.1902C15.9532 29.5753 14.7144 28.6788 13.3446 27.5437C10.6297 25.2939 7.28872 22.0166 5.11098 18.0491L5.10953 18.0465C4.84233 17.5578 4.13777 16.2398 4.71597 14.7986L4.71927 14.7904L7.369 8.27421C7.36916 8.27383 7.36953 8.2729 7.36969 8.27252ZM7.36969 8.27252C7.36962 8.2727 7.36976 8.27233 7.36969 8.27252Z"
        stroke="#6321AE"
        strokeWidth={3.77054}
      />
      <mask
        id="mask0_4101_7162"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={6}
        y={6}
        width={25}
        height={24}
      >
        <path
          d="M19.3107 29.5389C19.3107 29.5389 10.7912 24.4793 6.76364 17.1419C6.48632 16.6347 6.24573 16.0487 6.46566 15.5005L9.11608 8.98262C9.27494 8.59086 9.58784 8.33456 9.94788 8.30092L24.8465 6.69797C25.1942 6.66579 25.5421 6.84862 25.7742 7.18554L30.1291 12.7126C30.4771 13.2158 30.3215 13.8485 30.1758 14.4231C28.1957 22.2315 20.819 29.4139 20.819 29.4139C20.4531 29.9661 19.7774 30.0193 19.3148 29.5384L19.3107 29.5389Z"
          fill="#9EC0E9"
        />
      </mask>
      <g mask="url(#mask0_4101_7162)">
        <path
          d="M14.4784 5.34055C9.79472 5.5564 6.46016 11.4727 7.03048 18.5549C7.60081 25.6371 11.8601 31.2034 16.5438 30.9875C21.2275 30.7717 24.5621 24.8554 23.9917 17.7732C23.4214 10.691 19.1622 5.12469 14.4784 5.34055Z"
          fill="#D0A8FF"
        />
      </g>
      <mask
        id="mask1_4101_7162"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={6}
        y={6}
        width={25}
        height={24}
      >
        <path
          d="M19.3107 29.5389C19.3107 29.5389 10.7912 24.4793 6.76364 17.1419C6.48632 16.6347 6.24573 16.0487 6.46566 15.5005L9.11608 8.98262C9.27494 8.59086 9.58784 8.33456 9.94788 8.30092L24.8465 6.69797C25.1942 6.66579 25.5421 6.84862 25.7742 7.18554L30.1291 12.7126C30.4771 13.2158 30.3215 13.8485 30.1758 14.423C28.1957 22.2315 20.819 29.4139 20.819 29.4139C20.4531 29.9661 19.7774 30.0193 19.3148 29.5384L19.3107 29.5389Z"
          fill="#FACA83"
        />
      </mask>
      <g mask="url(#mask1_4101_7162)">
        <path
          d="M5.66272 16.3921C11.165 18.0198 25.3997 16.4945 31.1037 13.3947"
          stroke="#E5CEFF"
          strokeWidth={1.50689}
        />
        <path
          d="M15.5234 6.12646C10.5717 15.308 14.0711 24.5257 21.071 31.2675"
          stroke="#E5CEFF"
          strokeWidth={1.50689}
        />
        <path
          d="M19.238 5.68884C24.5004 11.9474 24.034 23.352 20.3227 31.3557"
          stroke="#E5CEFF"
          strokeWidth={1.50689}
        />
      </g>
      <path
        d="M10.3343 11.8169C10.3907 11.5196 10.7946 11.4681 10.9239 11.7417L12.5635 15.2133C12.6062 15.3037 12.6896 15.3682 12.7878 15.3868L16.5598 16.1024C16.8572 16.1588 16.9087 16.5627 16.635 16.692L13.1635 18.3316C13.0731 18.3743 13.0085 18.4577 12.9899 18.5559L12.2743 22.3279C12.2179 22.6253 11.814 22.6768 11.6847 22.4031L10.0451 18.9316C10.0024 18.8412 9.91902 18.7767 9.82079 18.758L6.0488 18.0425C5.75144 17.9861 5.69992 17.5821 5.97359 17.4529L9.44512 15.8132C9.53553 15.7705 9.60005 15.6872 9.61869 15.5889L10.3343 11.8169Z"
        fill="white"
      />
      <path
        d="M26.5 4L31.5 4.2L36.0551 11.2122C36.9566 12.6396 37.0769 14.4255 36.375 15.961L29.5 31H23.5L32 13.5L26.5 4Z"
        fill="#6321AE"
        stroke="#6321AE"
      />
    </g>
    <defs>
      <clipPath id="clip0_4101_7162">
        <rect width={38} height={37} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const YellowDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.7701 32.4887C22.7701 32.4887 14.3147 27.3225 10.3796 19.9351C10.1087 19.4246 9.87549 18.8355 10.1023 18.2901L12.8343 11.806C12.9981 11.4163 13.3142 11.1639 13.6746 11.1348L28.5922 9.71902C28.9402 9.6912 29.2858 9.87839 29.5137 10.2182L33.7989 15.7995C34.1405 16.307 33.977 16.9378 33.8241 17.5104C31.7461 25.2934 24.2798 32.3826 24.2798 32.3826C23.907 32.9302 23.2306 32.975 22.7742 32.4883L22.7701 32.4887Z"
      fill="#CC9600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5427 38.1591L21.3764 38.6685L21.8288 38.621C22.5043 38.7903 23.2234 38.8609 23.9715 38.8093C25.9185 38.6751 27.5735 37.745 28.7167 36.4364C28.7592 36.3942 28.8058 36.3478 28.8561 36.2972C29.107 36.0454 29.4535 35.6898 29.8694 35.2434C30.6981 34.3539 31.8195 33.0849 33.0166 31.5395C35.3289 28.5542 38.2786 24.0879 39.6214 19.0584C39.6247 19.0461 39.6289 19.0309 39.6338 19.0129C39.7044 18.7536 39.9317 17.9191 39.9869 16.9845C40.0535 15.8574 39.9102 14.1333 38.7767 12.4493L38.6723 12.2942L34.3358 6.64609C33.1098 4.96871 30.9101 3.51492 28.1146 3.73833L28.0701 3.74188L13.1329 5.15954C10.1518 5.42476 8.15556 7.45838 7.3055 9.47656C7.30533 9.47697 7.30516 9.47738 7.30498 9.4778C7.30441 9.47917 7.30383 9.48054 7.30325 9.48191L4.56803 15.9736L4.56265 15.9865C3.20285 19.2564 4.74082 22.1168 5.05416 22.6995C5.06402 22.7179 5.07267 22.734 5.07998 22.7477L5.0845 22.7562C7.56191 27.4071 11.3021 31.1205 14.1649 33.554C15.6352 34.8037 16.9662 35.793 17.94 36.4767C18.4286 36.8198 18.8318 37.0892 19.1234 37.2792C19.1549 37.2997 19.1852 37.3194 19.2141 37.3381C19.6199 37.6549 20.065 37.9323 20.5427 38.1591ZM13.675 11.1351C13.3146 11.1642 12.9985 11.4165 12.8347 11.8063L10.1027 18.2904C9.87591 18.8357 10.1091 19.4248 10.3801 19.9354C14.3152 27.3228 22.7705 32.4889 22.7705 32.4889L22.7746 32.4885C23.231 32.9752 23.9074 32.9304 24.2802 32.3829C24.2802 32.3829 31.7465 25.2936 33.8245 17.5107C33.9774 16.938 34.1409 16.3073 33.7993 15.7997L29.5141 10.2184C29.2862 9.87864 28.9406 9.69144 28.5926 9.71926L13.675 11.1351Z"
      fill="#FFD766"
    />
    <mask
      id="mask0_4196_20634"
      maskUnits="userSpaceOnUse"
      x="10"
      y="9"
      width="24"
      height="24"
    >
      <path
        d="M22.7701 32.4887C22.7701 32.4887 14.3147 27.3225 10.3796 19.9351C10.1087 19.4246 9.87549 18.8355 10.1023 18.2901L12.8343 11.806C12.9981 11.4163 13.3142 11.1639 13.6746 11.1348L28.5922 9.71902C28.9402 9.6912 29.2858 9.87839 29.5137 10.2182L33.7989 15.7995C34.1405 16.307 33.977 16.9378 33.8241 17.5104C31.7461 25.2934 24.2798 32.3826 24.2798 32.3826C23.907 32.9302 23.2306 32.975 22.7742 32.4883L22.7701 32.4887Z"
        fill="#9EC0E9"
      />
    </mask>
    <g mask="url(#mask0_4196_20634)">
      <path
        d="M10.6284 21.3505C10.147 14.2616 13.5556 8.38773 18.2417 8.23069C22.9277 8.07365 27.1168 13.693 27.5981 20.7818C28.0795 27.8706 24.6709 33.7445 19.9849 33.9015C15.2988 34.0586 11.1098 28.4393 10.6284 21.3505Z"
        fill="#E5A900"
      />
    </g>
    <mask
      id="mask1_4196_20634"
      maskUnits="userSpaceOnUse"
      x="10"
      y="9"
      width="24"
      height="24"
    >
      <path
        d="M22.7701 32.4887C22.7701 32.4887 14.3147 27.3225 10.3796 19.9351C10.1087 19.4246 9.87549 18.8355 10.1023 18.2901L12.8343 11.806C12.9981 11.4163 13.3142 11.1639 13.6746 11.1348L28.5922 9.71902C28.9402 9.6912 29.2858 9.87839 29.5137 10.2182L33.7989 15.7995C34.1405 16.307 33.977 16.9378 33.8241 17.5104C31.7461 25.2934 24.2798 32.3826 24.2798 32.3826C23.907 32.9302 23.2306 32.975 22.7742 32.4883L22.7701 32.4887Z"
        fill="#FFF2CC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8343 11.806L10.1023 18.2901C9.87549 18.8355 10.1087 19.4246 10.3796 19.9351C14.3147 27.3225 22.7701 32.4887 22.7701 32.4887L22.7742 32.4883C23.2306 32.975 23.907 32.9302 24.2798 32.3826C24.2798 32.3826 31.7461 25.2934 33.8241 17.5104C33.977 16.9378 34.1405 16.307 33.7989 15.7995L29.5137 10.2182C29.2858 9.87839 28.9402 9.6912 28.5922 9.71902L13.6746 11.1348C13.3142 11.1639 12.9981 11.4163 12.8343 11.806ZM28.6284 10.14L13.7086 11.556C13.5352 11.57 13.339 11.6957 13.2239 11.9697L10.4924 18.4524C10.4923 18.4526 10.4925 18.4521 10.4924 18.4524C10.349 18.7984 10.4799 19.2226 10.7527 19.7368C12.6844 23.363 15.7366 26.4607 18.3242 28.6602C19.6152 29.7577 20.7845 30.6264 21.6302 31.2203C22.053 31.5171 22.3946 31.745 22.6298 31.8983C22.7301 31.9637 22.8111 32.0155 22.8707 32.0533L22.9388 32.0461L23.0824 32.1992C23.233 32.3598 23.396 32.4109 23.5293 32.4018C23.6615 32.3926 23.8104 32.3212 23.9305 32.1448L23.956 32.1074L23.9888 32.0762L23.9897 32.0754L23.9933 32.072L24.0083 32.0575C24.0144 32.0517 24.0219 32.0444 24.0308 32.0358C24.0416 32.0254 24.0544 32.013 24.0691 31.9986C24.1229 31.9461 24.2027 31.8676 24.3053 31.7646C24.5105 31.5586 24.807 31.2546 25.1697 30.8653C25.8953 30.0863 26.8849 28.9668 27.9386 27.6064C30.0519 24.8781 32.3983 21.2128 33.4159 17.4014C33.4941 17.1083 33.5608 16.8486 33.5751 16.6056C33.5886 16.3766 33.5529 16.1962 33.4548 16.0452L29.1703 10.4648L29.1628 10.4536C29.0033 10.2158 28.7947 10.1278 28.6284 10.14Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_4196_20634)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0046 20.355C17.0215 20.8597 11.9594 20.788 9.06543 19.8923L9.51101 18.4528C12.0985 19.2537 16.9035 19.3571 21.8527 18.8558C26.7951 18.3551 31.6747 17.2724 34.4134 15.8283L35.1162 17.1613C32.1125 18.7451 26.9944 19.8495 22.0046 20.355Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.98 34.7756C16.9228 27.8059 13.3299 18.1846 18.6192 8.66493L19.9364 9.3968C15.0927 18.1145 18.2665 27.015 25.0388 33.7035L23.98 34.7756Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5054 20.8647C27.4859 16.1256 26.3186 11.5039 23.5805 8.16308L22.415 9.1183C24.8602 12.1017 25.9799 16.3409 25.9986 20.8709C26.0172 25.3927 24.9368 30.1188 23.0806 33.9936L24.4396 34.6446C26.3949 30.563 27.525 25.6119 27.5054 20.8647Z"
        fill="white"
      />
    </g>
    <path
      d="M11.0808 14.7033C11.1519 14.4091 11.5579 14.3776 11.6734 14.6574L13.1394 18.2057C13.1776 18.2981 13.2577 18.3667 13.3549 18.3901L17.0869 19.2913C17.3811 19.3624 17.4126 19.7684 17.1328 19.8839L13.5845 21.3499C13.4921 21.3881 13.4235 21.4682 13.4 21.5654L12.4989 25.2974C12.4278 25.5916 12.0218 25.6231 11.9063 25.3433L10.4403 21.795C10.4021 21.7026 10.322 21.634 10.2248 21.6106L6.49282 20.7094C6.19862 20.6383 6.16712 20.2323 6.44685 20.1168L9.9952 18.6508C10.0876 18.6126 10.1562 18.5325 10.1796 18.4353L11.0808 14.7033Z"
      fill="white"
    />
  </svg>
);
