const DoubleQuotesIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
  >
    <path
      d="M14.9999 10.9993L21 10.9993C20.9745 6.8253 19.623 4.21499 16.8674 3.0222C16.1009 2.69061 15.7379 1.77127 16.0544 0.969786C16.3709 0.165162 17.2484 -0.208862 18.0134 0.118016C20.7435 1.29981 24 4.16628 24 11.1124L24 20.4285C24 21.2959 23.3295 22 22.5 22L14.9999 22C14.1704 22 13.4999 21.296 13.4999 20.4285L13.4999 12.5708C13.4999 11.7033 14.1704 10.9993 14.9999 10.9993Z"
      fill="#FBD6B4"
    />
    <path
      d="M1.49991 10.9993L7.49997 10.9993C7.47447 6.8253 6.12296 4.21499 3.36743 3.0222C2.60092 2.69061 2.23792 1.77127 2.55442 0.969786C2.87093 0.165162 3.74843 -0.208862 4.51344 0.118016C7.24347 1.29981 10.5 4.16785 10.5 11.1124L10.5 20.4285C10.5 21.2959 9.8295 22 8.99999 22L1.49991 22C0.670406 22 -0.000100278 21.296 -0.00010035 20.4285L-0.000101001 12.5708C-0.000101073 11.7033 0.670405 10.9993 1.49991 10.9993Z"
      fill="#FBD6B4"
    />
  </svg>
);
export default DoubleQuotesIcon;
