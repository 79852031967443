const ListToMap = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <defs>
        <animate
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_2_G_D_0_P_0"
          fill="freeze"
          attributeName="d"
          attributeType="XML"
          from="M239.75 237 C239.75,237 239.75,245 239.75,245 C239.75,245 239.75,243 239.75,243 C239.75,243 239.75,245.65 239.75,245.65 C239.75,245.65 239.75,243 239.75,243 C239.75,243 239.75,235 239.75,235 C239.75,235 239.75,237.65 239.75,237.65 C239.75,237.65 239.75,235 239.75,235 C239.75,235 239.75,237 239.75,237z "
          to="M234.5 237 C234.5,237 234.5,245 234.5,245 C234.5,245 238,243 238,243 C238,243 242,245 242,245 C242,245 245.5,243 245.5,243 C245.5,243 245.5,235 245.5,235 C245.5,235 242,237 242,237 C242,237 238,235 238,235 C238,235 234.5,237 234.5,237z "
          keyTimes="0;0.4545455;0.7272727;0.9545455;1"
          values="M239.75 237 C239.75,237 239.75,245 239.75,245 C239.75,245 239.75,243 239.75,243 C239.75,243 239.75,245.65 239.75,245.65 C239.75,245.65 239.75,243 239.75,243 C239.75,243 239.75,235 239.75,235 C239.75,235 239.75,237.65 239.75,237.65 C239.75,237.65 239.75,235 239.75,235 C239.75,235 239.75,237 239.75,237z ;M239.75 237 C239.75,237 239.75,245 239.75,245 C239.75,245 239.75,243 239.75,243 C239.75,243 239.75,245.65 239.75,245.65 C239.75,245.65 239.75,243 239.75,243 C239.75,243 239.75,235 239.75,235 C239.75,235 239.75,237.65 239.75,237.65 C239.75,237.65 239.75,235 239.75,235 C239.75,235 239.75,237 239.75,237z ;M234 236.6 C234,236.6 234,244.6 234,244.6 C234,244.6 237.75,243 237.75,243 C237.75,243 242.25,244.8 242.25,244.8 C242.25,244.8 246.2,243 246.2,243 C246.2,243 246.2,235 246.2,235 C246.2,235 242.25,236.8 242.25,236.8 C242.25,236.8 237.75,235 237.75,235 C237.75,235 234,236.6 234,236.6z ;M234.5 237 C234.5,237 234.5,245 234.5,245 C234.5,245 238,243 238,243 C238,243 242,245 242,245 C242,245 245.5,243 245.5,243 C245.5,243 245.5,235 245.5,235 C245.5,235 242,237 242,237 C242,237 238,235 238,235 C238,235 234.5,237 234.5,237z ;M234.5 237 C234.5,237 234.5,245 234.5,245 C234.5,245 238,243 238,243 C238,243 242,245 242,245 C242,245 245.5,243 245.5,243 C245.5,243 245.5,235 245.5,235 C245.5,235 242,237 242,237 C242,237 238,235 238,235 C238,235 234.5,237 234.5,237z "
          keySplines="0.333 0 0 0.992;0.333 0 0 0.992;0.167 0 0 0.992;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_2_G_D_1_P_0"
          fill="freeze"
          attributeName="d"
          attributeType="XML"
          from="M239.75 235 C239.75,235 239.75,243 239.75,243 "
          to="M238 235 C238,235 238,243 238,243 "
          keyTimes="0;0.4545455;0.7272727;0.9545455;1"
          values="M239.75 235 C239.75,235 239.75,243 239.75,243 ;M239.75 235 C239.75,235 239.75,243 239.75,243 ;M237.75 235 C237.75,235 237.75,243 237.75,243 ;M238 235 C238,235 238,243 238,243 ;M238 235 C238,235 238,243 238,243 "
          keySplines="0.333 0 0 0.992;0.333 0 0 0.992;0.167 0 0 0.992;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_2_G_D_2_P_0"
          fill="freeze"
          attributeName="d"
          attributeType="XML"
          from="M239.75 237 C239.75,237 239.75,245.63 239.75,245.63 "
          to="M242 237 C242,237 242,245 242,245 "
          keyTimes="0;0.4545455;0.7272727;0.9545455;1"
          values="M239.75 237 C239.75,237 239.75,245.63 239.75,245.63 ;M239.75 237 C239.75,237 239.75,245.63 239.75,245.63 ;M242.25 236.8 C242.25,236.8 242.25,244.8 242.25,244.8 ;M242 237 C242,237 242,245 242,245 ;M242 237 C242,237 242,245 242,245 "
          keySplines="0.333 0 0 0.992;0.333 0 0 0.992;0.167 0 0 0.992;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_2_G_N_1_T_0_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4545455;0.45455;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateMotion
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_0_P_0_G_0_T_0"
          fill="freeze"
          keyTimes="0;0.0909091;0.4545455;1"
          path="M239.72 243.5 C239.72,243.5 239.72,243.5 239.72,243.5 C239.72,243.5 239.72,240 239.72,240 C239.72,240 239.72,240 239.72,240 "
          keyPoints="0;0;1;1"
          keySplines="0.333 0 0.145 0.981;0.333 0 0.145 0.981;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_0_P_0_G_0_T_0"
          fill="freeze"
          attributeName="transform"
          from="-239.723 -243.5"
          to="-239.723 -243.5"
          type="translate"
          additive="sum"
          keyTimes="0;1"
          values="-239.723 -243.5;-239.723 -243.5"
          keySplines="0 0 1 1"
          calcMode="spline"
        />
        <animate
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_0_P_0"
          fill="freeze"
          attributeName="d"
          attributeType="XML"
          from="M-3.79 0 C-3.79,0 3.79,0 3.79,0 "
          to="M-6.72 0 C-6.72,0 3.79,0 3.79,0 "
          keyTimes="0;0.3636364;1"
          values="M-3.79 0 C-3.79,0 3.79,0 3.79,0 ;M-6.72 0 C-6.72,0 3.79,0 3.79,0 ;M-6.72 0 C-6.72,0 3.79,0 3.79,0 "
          keySplines="0.333 0 0.145 0.753;0 0 0 0"
          calcMode="spline"
        />
        <animateMotion
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_1_P_0_G_0_T_0"
          fill="freeze"
          keyTimes="0;0.0909091;0.4545455;1"
          path="M239.72 243.5 C239.72,243.5 239.72,243.5 239.72,243.5 C239.72,243.5 239.72,240 239.72,240 C239.72,240 239.72,240 239.72,240 "
          keyPoints="0;0;1;1"
          keySplines="0.333 0 0.145 0.981;0.333 0 0.145 0.981;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_1_P_0_G_0_T_0"
          fill="freeze"
          attributeName="transform"
          from="-239.723 -243.5"
          to="-239.723 -243.5"
          type="translate"
          additive="sum"
          keyTimes="0;1"
          values="-239.723 -243.5;-239.723 -243.5"
          keySplines="0 0 1 1"
          calcMode="spline"
        />
        <animate
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_2_P_0"
          fill="freeze"
          attributeName="d"
          attributeType="XML"
          from="M237.67 240 C237.67,240 245.25,240 245.25,240 "
          to="M234.77 240 C234.77,240 245.25,240 245.25,240 "
          keyTimes="0;0.3636364;1"
          values="M237.67 240 C237.67,240 245.25,240 245.25,240 ;M234.77 240 C234.77,240 245.25,240 245.25,240 ;M234.77 240 C234.77,240 245.25,240 245.25,240 "
          keySplines="0.333 0 0.145 0.753;0 0 0 0"
          calcMode="spline"
        />
        <animateMotion
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_4_P_0_G_0_T_0"
          fill="freeze"
          keyTimes="0;0.0909091;0.4545455;1"
          path="M239.72 236.5 C239.72,236.5 239.72,236.5 239.72,236.5 C239.72,236.5 239.72,240 239.72,240 C239.72,240 239.72,240 239.72,240 "
          keyPoints="0;0;1;1"
          keySplines="0.333 0 0.145 0.981;0.333 0 0.145 0.981;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_4_P_0_G_0_T_0"
          fill="freeze"
          attributeName="transform"
          from="-239.723 -236.5"
          to="-239.723 -236.5"
          type="translate"
          additive="sum"
          keyTimes="0;1"
          values="-239.723 -236.5;-239.723 -236.5"
          keySplines="0 0 1 1"
          calcMode="spline"
        />
        <animate
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_4_P_0"
          fill="freeze"
          attributeName="d"
          attributeType="XML"
          from="M-3.79 0 C-3.79,0 3.79,0 3.79,0 "
          to="M-6.69 0 C-6.69,0 3.79,0 3.79,0 "
          keyTimes="0;0.3636364;1"
          values="M-3.79 0 C-3.79,0 3.79,0 3.79,0 ;M-6.69 0 C-6.69,0 3.79,0 3.79,0 ;M-6.69 0 C-6.69,0 3.79,0 3.79,0 "
          keySplines="0.333 0 0.145 0.753;0 0 0 0"
          calcMode="spline"
        />
        <animateMotion
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_5_P_0_G_0_T_0"
          fill="freeze"
          keyTimes="0;0.0909091;0.4545455;1"
          path="M239.72 236.5 C239.72,236.5 239.72,236.5 239.72,236.5 C239.72,236.5 239.72,240 239.72,240 C239.72,240 239.72,240 239.72,240 "
          keyPoints="0;0;1;1"
          keySplines="0.333 0 0.145 0.981;0.333 0 0.145 0.981;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_D_5_P_0_G_0_T_0"
          fill="freeze"
          attributeName="transform"
          from="-239.723 -236.5"
          to="-239.723 -236.5"
          type="translate"
          additive="sum"
          keyTimes="0;1"
          values="-239.723 -236.5;-239.723 -236.5"
          keySplines="0 0 1 1"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G"
          fill="freeze"
          attributeName="transform"
          from="0"
          to="90"
          type="rotate"
          additive="sum"
          keyTimes="0;0.6363636;1"
          values="0;90;90"
          keySplines="0.333 0 0.153 0.979;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G"
          fill="freeze"
          attributeName="transform"
          from="-239.723 -240"
          to="-239.723 -240"
          type="translate"
          additive="sum"
          keyTimes="0;1"
          values="-239.723 -240;-239.723 -240"
          keySplines="0 0 1 1"
          calcMode="spline"
        />
        <animate
          repeatCount="1"
          dur="0.7333333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_N_1_T_0_M"
          fill="freeze"
          attributeName="opacity"
          from="1"
          to="0"
          keyTimes="0;0.5454545;0.5454546;1"
          values="1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animate
          attributeType="XML"
          attributeName="opacity"
          dur="1s"
          from="0"
          to="1"
          xlinkHref="#time_group"
        />
      </defs>
      <g id="_R_G">
        <g id="_R_G_L_2_G_N_1_T_0_M">
          <g
            id="_R_G_L_2_G_N_1_T_0"
            transform=" translate(7, 7) translate(0, 0)"
          >
            <g
              id="_R_G_L_2_G"
              transform=" translate(0, 0) translate(-240, -240)"
            >
              <path
                id="_R_G_L_2_G_D_0_P_0"
                stroke="#8000ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                strokeWidth="0.889"
                strokeOpacity="1"
                d=" M239.75 237 C239.75,237 239.75,245 239.75,245 C239.75,245 239.75,243 239.75,243 C239.75,243 239.75,245.65 239.75,245.65 C239.75,245.65 239.75,243 239.75,243 C239.75,243 239.75,235 239.75,235 C239.75,235 239.75,237.65 239.75,237.65 C239.75,237.65 239.75,235 239.75,235 C239.75,235 239.75,237 239.75,237z "
              />
              <path
                id="_R_G_L_2_G_D_1_P_0"
                stroke="#8000ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                strokeWidth="0.889"
                strokeOpacity="1"
                d=" M239.75 235 C239.75,235 239.75,243 239.75,243 "
              />
              <path
                id="_R_G_L_2_G_D_2_P_0"
                stroke="#8000ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                strokeWidth="0.889"
                strokeOpacity="1"
                d=" M239.75 237 C239.75,237 239.75,245.63 239.75,245.63 "
              />
            </g>
          </g>
        </g>
        <g id="_R_G_L_1_G_N_1_T_0_M">
          <g
            id="_R_G_L_1_G_N_1_T_0"
            transform=" translate(7, 7) translate(0, 0)"
          >
            <g id="_R_G_L_1_G" transform=" translate(-0.277, 0)">
              <g id="_R_G_L_1_G_D_0_P_0_G_0_T_0">
                <g
                  id="_R_G_L_1_G_D_0_P_0_G_1_T_0"
                  transform=" translate(241.458, 243.5) translate(0, 0)"
                >
                  <path
                    id="_R_G_L_1_G_D_0_P_0"
                    stroke="#444444"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    strokeWidth="1.111"
                    strokeOpacity="1"
                    d=" M-3.79 0 C-3.79,0 3.79,0 3.79,0 "
                  />
                </g>
              </g>
              <g id="_R_G_L_1_G_D_1_P_0_G_0_T_0">
                <g
                  id="_R_G_L_1_G_D_1_P_0_G_1_T_0"
                  transform=" translate(234.754, 243.5) translate(0, 0)"
                >
                  <path
                    id="_R_G_L_1_G_D_1_P_0"
                    fill="#444444"
                    fillOpacity="1"
                    fillRule="nonzero"
                    d=" M0 0.56 C-0.31,0.56 -0.56,0.31 -0.56,0 C-0.56,-0.31 -0.31,-0.56 0,-0.56 C0,-0.56 0,-0.56 0,-0.56 C0.31,-0.56 0.56,-0.31 0.56,0 C0.56,0.31 0.31,0.56 0,0.56z "
                  />
                </g>
              </g>
              <path
                id="_R_G_L_1_G_D_2_P_0"
                stroke="#444444"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                strokeWidth="1.111"
                strokeOpacity="1"
                d=" M237.67 240 C237.67,240 245.25,240 245.25,240 "
              />
              <path
                id="_R_G_L_1_G_D_3_P_0"
                fill="#444444"
                fillOpacity="1"
                fillRule="nonzero"
                d=" M234.76 240.56 C234.45,240.56 234.2,240.31 234.2,240 C234.2,239.69 234.44,239.44 234.75,239.44 C234.75,239.44 234.76,239.44 234.76,239.44 C235.06,239.44 235.31,239.69 235.31,240 C235.31,240.31 235.06,240.56 234.76,240.56z "
              />
              <g id="_R_G_L_1_G_D_4_P_0_G_0_T_0">
                <g
                  id="_R_G_L_1_G_D_4_P_0_G_1_T_0"
                  transform=" translate(241.458, 236.5) translate(0, 0)"
                >
                  <path
                    id="_R_G_L_1_G_D_4_P_0"
                    stroke="#444444"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    strokeWidth="1.111"
                    strokeOpacity="1"
                    d=" M-3.79 0 C-3.79,0 3.79,0 3.79,0 "
                  />
                </g>
              </g>
              <g id="_R_G_L_1_G_D_5_P_0_G_0_T_0">
                <g
                  id="_R_G_L_1_G_D_5_P_0_G_1_T_0"
                  transform=" translate(234.754, 236.5) translate(0, 0)"
                >
                  <path
                    id="_R_G_L_1_G_D_5_P_0"
                    fill="#444444"
                    fillOpacity="1"
                    fillRule="nonzero"
                    d=" M0 0.56 C-0.31,0.56 -0.56,0.31 -0.56,0 C-0.56,-0.31 -0.31,-0.56 0,-0.56 C0,-0.56 0,-0.56 0,-0.56 C0.31,-0.56 0.56,-0.31 0.56,0 C0.56,0.31 0.31,0.56 0,0.56z "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="_R_G_L_0_G" transform=" translate(7, 7) translate(0, 0)" />
      </g>
      <g id="time_group" />
    </svg>
  );
};

export default ListToMap;
