import { SVGProps } from 'react';

const EmptyMarker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={39}
    viewBox="0 0 32 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 0C7.163 0 0 7.163 0 16c0 6.422 3.784 11.96 9.244 14.508 2.031.948 3.955 2.256 5.03 4.223l1.392 2.547c.202.37.733.37.936 0l1.477-2.701c1.05-1.919 2.91-3.212 4.878-4.164C28.309 27.824 32 22.343 32 16c0-8.837-7.163-16-16-16"
      fill="#8000FF"
    />
    <path
      d="M.5 16C.5 7.44 7.44.5 16 .5S31.5 7.44 31.5 16c0 6.144-3.575 11.455-8.76 13.962-2.007.97-3.977 2.322-5.1 4.375l-1.477 2.7q-.008.014-.012.014l-.017.004a.04.04 0 0 1-.018-.004q-.003 0-.012-.013l-1.391-2.547c-1.15-2.104-3.188-3.47-5.258-4.436C4.165 27.587.5 22.22.5 16Z"
      stroke="#000"
      strokeOpacity={0.2}
    />
    <rect x={3} y={3} width={26} height={26} rx={13} fill="#F8F6FF" />
  </svg>
);
export default EmptyMarker;
