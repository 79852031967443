const Route = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.625 3.625C1.625 5.00571 2.74429 6.125 4.125 6.125C5.50571 6.125 6.625 5.00571 6.625 3.625C6.625 2.24429 5.50571 1.125 4.125 1.125C2.74429 1.125 1.625 2.24429 1.625 3.625Z"
      stroke="#6600CC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.375 17.375C15.375 18.7557 16.4943 19.875 17.875 19.875C19.2557 19.875 20.375 18.7557 20.375 17.375C20.375 15.9943 19.2557 14.875 17.875 14.875C16.4943 14.875 15.375 15.9943 15.375 17.375Z"
      stroke="#6600CC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.125 3.625L16.9375 3.625C17.8492 3.625 18.7235 3.98716 19.3682 4.63182C20.0128 5.27648 20.375 6.15082 20.375 7.0625C20.375 7.97418 20.0128 8.84852 19.3682 9.49318C18.7235 10.1378 17.8492 10.5 16.9375 10.5L5.0625 10.5C4.15082 10.5 3.27648 10.8622 2.63182 11.5068C1.98716 12.1515 1.625 13.0258 1.625 13.9375C1.625 14.8492 1.98716 15.7235 2.63182 16.3682C3.27648 17.0128 4.15082 17.375 5.0625 17.375L12.875 17.375"
      stroke="#6600CC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Route;
