import React from 'react';

export const LegendMarker = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5163 26.383L14.2795 26.8443L14.5152 26.9654L14.7515 26.8452L14.5163 26.383ZM14.5163 26.383L14.7515 26.8451L14.7516 26.8451L14.7521 26.8448L14.7535 26.8441L14.7589 26.8414L14.7794 26.8309L14.858 26.7903C14.9266 26.7548 15.0267 26.7027 15.154 26.6356C15.4085 26.5013 15.7717 26.3071 16.2079 26.0662C17.0795 25.585 18.2459 24.9159 19.4203 24.1668C21.056 23.1235 22.7694 21.8866 23.692 20.7628C25.4096 18.6707 26.4422 15.9812 26.4422 13.0497C26.4422 6.3877 21.1091 0.975622 14.5163 0.975622C7.9235 0.975622 2.59037 6.3877 2.59037 13.0497C2.59037 16.4715 3.99707 19.5627 6.25743 21.76C7.03363 22.5146 8.29407 23.3811 9.55941 24.1697C10.7052 24.8839 11.885 25.5523 12.7766 26.0413C13.2228 26.2861 13.5978 26.4865 13.8616 26.6258C13.9934 26.6955 14.0975 26.7499 14.1688 26.787L14.2506 26.8294L14.2719 26.8404L14.2774 26.8433L14.2789 26.844L14.2793 26.8442L14.2794 26.8443L14.5163 26.383Z"
        fill="#7410F8"
        stroke="black"
        strokeWidth="1.03704"
      />
      <circle
        cx="14.5378"
        cy="13.4617"
        r="5.42381"
        fill="white"
        stroke="black"
        strokeWidth="0.998543"
      />
    </svg>
  );
};
