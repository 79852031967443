import { SVGProps } from 'react';

const LocationPin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 6.667c0 4.666-6 8.666-6 8.666s-6-4-6-8.666a6 6 0 1 1 12 0"
      stroke="#444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 8.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      stroke="#444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LocationPin;
