import { SVGProps } from 'react';

const Plus = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99992 3.33333L7.99992 12.6667M3.33325 8L12.6666 8"
      stroke="#114CD6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Plus;
