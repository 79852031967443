import { SVGProps } from 'react';

const Attraction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 13a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v1.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM3 10.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5V12a.5.5 0 0 1-.5.5h-9A.5.5 0 0 1 3 12zm4.933-9.008a.074.074 0 0 1 .134 0l.939 2.084c.01.024.033.04.059.043l2.273.249a.074.074 0 0 1 .041.127L9.687 5.532a.07.07 0 0 0-.023.07l.466 2.238a.074.074 0 0 1-.109.079L8.037 6.784a.07.07 0 0 0-.074 0L5.98 7.92a.074.074 0 0 1-.109-.079l.466-2.238a.07.07 0 0 0-.023-.07L4.621 3.995a.074.074 0 0 1 .041-.127l2.273-.249a.07.07 0 0 0 .06-.043z"
      stroke="#444"
    />
  </svg>
);

export default Attraction;
