const EngagingStoriesIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
  >
    <path
      d="M14.5625 11.1962C14.5648 12.0807 14.3581 12.9532 13.9594 13.7427C13.4865 14.6888 12.7596 15.4845 11.8601 16.0408C10.9606 16.5971 9.92395 16.892 8.86631 16.8924C7.98182 16.8947 7.10928 16.688 6.31979 16.2892L2.5 17.5625L3.77326 13.7427C3.37448 12.9532 3.16783 12.0807 3.17014 11.1962C3.17055 10.1386 3.46541 9.10191 4.02169 8.20238C4.57798 7.30286 5.37372 6.57597 6.31979 6.10314C7.10928 5.70437 7.98182 5.49771 8.86631 5.50002H9.20138C10.5982 5.57708 11.9175 6.16665 12.9067 7.15584C13.8959 8.14502 14.4854 9.46432 14.5625 10.8611V11.1962Z"
      fill="#B64E80"
    />
    <path
      d="M14.9709 1.00011C15.0596 0.804931 15.3368 0.804931 15.4256 1.00011L16.3075 2.94041C16.3375 3.00637 16.3904 3.05923 16.4563 3.08921L18.3966 3.97117C18.5918 4.05988 18.5918 4.33711 18.3966 4.42583L16.4563 5.30778C16.3903 5.33776 16.3375 5.39062 16.3075 5.45658L15.4256 7.39689C15.3368 7.59206 15.0596 7.59206 14.9709 7.39689L14.0889 5.45658C14.059 5.39062 14.0061 5.33776 13.9401 5.30778L11.9998 4.42583C11.8047 4.33711 11.8047 4.05988 11.9998 3.97117L13.9401 3.08921C14.0061 3.05923 14.059 3.00637 14.0889 2.94041L14.9709 1.00011Z"
      fill="#B64E80"
    />
    <circle cx="6.39062" cy="11.1406" r="0.890625" fill="#F8F6FF" />
    <circle cx="9.0625" cy="11.1406" r="0.890625" fill="#F8F6FF" />
    <circle cx="11.7344" cy="11.1406" r="0.890625" fill="#F8F6FF" />
  </svg>
);
export default EngagingStoriesIcon;
