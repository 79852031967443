const TopRatedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
  >
    <path
      d="M11.7833 0.936376C12.058 0.501645 12.692 0.501646 12.9667 0.936376L14.6342 3.57497C14.7298 3.72625 14.8797 3.83518 15.0532 3.87935L18.0779 4.64983C18.5762 4.77677 18.7721 5.37977 18.4436 5.77539L16.4494 8.17661C16.3351 8.31428 16.2778 8.49052 16.2894 8.66911L16.4913 11.7839C16.5246 12.2971 16.0116 12.6697 15.5339 12.4795L12.6339 11.325C12.4677 11.2588 12.2823 11.2588 12.1161 11.325L9.21615 12.4795C8.73836 12.6697 8.22542 12.2971 8.25869 11.7839L8.46061 8.66911C8.47219 8.49052 8.41492 8.31428 8.30059 8.17661L6.30641 5.77539C5.97785 5.37977 6.17378 4.77677 6.67213 4.64983L9.69685 3.87935C9.87027 3.83518 10.0202 3.72625 10.1158 3.57497L11.7833 0.936376Z"
      fill="#B64E80"
    />
    <path
      d="M11.7812 13.0625L9.40625 15.4375"
      stroke="#B64E80"
      strokeWidth="1.1875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.03125 8.90625L4.65625 11.2812"
      stroke="#B64E80"
      strokeWidth="1.1875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.03125 13.0625L2.875 17.2188"
      stroke="#B64E80"
      strokeWidth="1.1875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TopRatedIcon;
