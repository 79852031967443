const Leafmobile = (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 29 29"
    xmlSpace="preserve"
  >
    <style type="text/css">{'\n.st0{fill:#330066;}\n'}</style>
    <g>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1.8s"
        from={0}
        to={0}
        values="0 20 28;12 20 28;0 20 28"
        keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
        calcMode="spline"
        repeatCount="indefinite"
      />
      <g>
        <g>
          <path
            className="st0"
            d="M10.9,12.3C7.3,9.4,7.3,3.2,11.4,0.9C12.7,4.7,12,8.8,10.9,12.3z"
          />
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1.8s"
            from={0}
            to={0}
            values="0 12 14;8 12 14;0 12 14"
            keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
            calcMode="spline"
            begin={0.4}
            repeatCount="indefinite"
          />
        </g>
        <path
          className="st0"
          d="M7.4,9.6C10,12.6,11.9,16.2,13,20C8,19.2,5.2,13.2,6.6,8.6C6.7,8.7,7.2,9.3,7.4,9.6z"
        />
        <path
          className="st0"
          d="M15.3,15.1c-0.1,1.2-0.4,2.8-0.7,3.9c-3.2-2.2-4.1-7.8-0.3-9.9C15,10.9,15.5,13.1,15.3,15.1z"
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1.8s"
          from={0}
          to={0}
          values="0 14 20;8 14 20;0 14 20"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          calcMode="spline"
          begin={0.2}
          repeatCount="indefinite"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M12.7,24.3c-1.1-1.1-2-2.7-2.4-4.1c3.6,1,7,3.2,9.4,6.1C17.2,27,14.4,26.1,12.7,24.3z"
        />
        <path
          className="st0"
          d="M20.5,24.7c0,0.1,0,0.1,0,0.1c-3.4-0.7-6-4.7-4.5-8C18.6,18.6,20.1,21.6,20.5,24.7z"
        />
      </g>
    </g>
  </svg>
);
export default Leafmobile;
