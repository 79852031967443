const DiagonalArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M5.07112 13.5561L13.5564 5.0708"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.07109 5.07084H13.5564V13.5561"
      stroke="#444444"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DiagonalArrow;
