const MealBackground = () => (
  <svg
    width="58"
    height="77"
    viewBox="0 0 58 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="58" height="77" fill="#FCE5A9" />
    <rect width="12" height="77" fill="#906100" />
  </svg>
);
export default MealBackground;
